/**
 * Makes ID iframes
 */
export function setIframeDomain(hostname = "") {
  const tp = window.tp;

  // For staging test/envs, be explicit
  if (["main-www.scientificamerican.com", "local-www.scientificamerican.com"].includes(hostname)) {
    tp.push(["setCookieDomain", hostname]);
  } else if (hostname.endsWith(".scientificamerican.com")) {
    // For prod, set it at the top level
    tp.push(["setCookieDomain", "scientificamerican.com"]);
  }

  // Only works on our domain
  if (hostname === "www.scientificamerican.com") {
    tp.push(["setComposerHost", "https://c2.tp.scientificamerican.com"]);
    tp.push(["setPianoIdUrl", "https://id.tp.scientificamerican.com"]);
    tp.push(["setEndpoint", "https://vx.tp.scientificamerican.com"]);
  }
}

export function setPaywallExempt() {
  window.tp.push(["setTags", ["paywall-exempt"]]);
}

/**
 * @param {import('@sciam/shared').DataLayerContent} dataLayerContent
 */
export function setCustomVariables(dataLayerContent) {
  /* Custom Variables to send to Piano */
  [
    ["language", "en"],
    ["primaryCategory", dataLayerContent.primaryCategory || ""],
    ["subCategory", dataLayerContent.subCategory || ""],
    ["subtype", dataLayerContent.subtype || ""],
    ["type", dataLayerContent.type || ""],
    ["contentId", ""],
    ["collectionId", ""],
    ["collectionName", ""],
    ["publishedAtDateTime", dataLayerContent.publishedAtDateTime || ""],
    ["publishedAtDate", dataLayerContent.publishedAtDate || ""],
    ["publishedAtTime", dataLayerContent.publishedAtTime || ""],
    ["updatedAtDateTime", dataLayerContent.updatedAtDateTime || ""],
    ["brand", ""],
    ["authors", dataLayerContent.authors || []],
    ["title", dataLayerContent.title || ""],
    ["tags", ""],
    ["template", ""],
    ["isSyndicated", false],
    ["isPartner", false],
    ["isSponsored", false],
    ["isResalable", false],
    ["containsMedia", ""],
    ["articleDoi", dataLayerContent.articleDoi || ""],
    ["journalIssueName", dataLayerContent.journalIssueName || ""],
    ["wordCount", undefined],
  ].forEach(([key, value]) => window.tp.push(["setCustomVariable", key, value]));
}
